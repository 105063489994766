@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,600;1,600&display=swap');

.App {
  text-align: center;
  font-family: "Playfair Display", serif;
  min-height: 100vh;
  background-color: #fdfdff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

h1 {
  font-weight: 700;
  margin-bottom: 20px;
}

.sprite-container {
  background-repeat: no-repeat;
  image-rendering: pixelated;
}

.rabbit-container {
  width: 64px;  /* Adjust based on your sprite size */
  height: 64px; /* Adjust based on your sprite size */
  margin-top: 20px;
  overflow: visible; /* Allow the sprite to overflow its container */
}

.rabbit-sprite {
  width: 64px;  /* Adjust based on your sprite size */
  height: 64px; /* Adjust based on your sprite size */
  background-repeat: no-repeat;
  background-size: 256px 384px; /* 4 columns * 64px, 6 rows * 64px */
  image-rendering: pixelated;
  transform-origin: center bottom; /* This ensures scaling doesn't cut off the bottom */
  transition: transform 0.15s ease-in-out; /* Add smooth transition between frames */
  transform: scale(3); /* Scale up the sprite */
}

.services-section {
  padding: 2rem;
  background-color: #282c34;
  color: white;
}

.services-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.service-item {
  flex: 1;
  padding: 1rem;
  border: 1px solid #61dafb;
  border-radius: 8px;
  text-align: center;
}

.service-item h3 {
  color: #61dafb;
}

.services {
  margin-top: 2rem;
  text-align: center;
}

.service-list {
  margin-top: 2rem;
  font-size: 1.2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.contact-button {
  margin-top: 2rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid black;
  color: black;
  text-decoration: none;
}

.contact-button:hover {
  background: black;
  color: white;
  text-decoration: none;
}

a {
  margin-top: 2rem;
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Keep any other existing styles you want to retain */
